$ = jQuery;

$(document).ready(function () {

  $('.recebe-alertas').on('click', function () {
    $.ajax({
      url: admin.url,
      type: 'POST',
      data:{
        'action': 'jc_ajax_set_transactional_email',
        'transacional_email': $(this).attr('id').split('_')[0],
        'user_id': $(this).attr('id').split('_')[1],
        'val': $(this).is(':checked') ? true : false
      },
      success: function() {
        $('.recebe-alertas').is(':checked') ? $('.form-receive-email').css('display','block').show(): $('.form-receive-email').css('display','none').hide();
      },
    });
  });

  $('.transactional_email').on('click', function () {
    $.ajax({
      url: admin.url,
      type: 'POST',
      data:{
        'action': 'jc_ajax_set_transactional_email',
        'transacional_email': $(this).attr('id').split('_')[0],
        'user_id': $(this).attr('id').split('_')[1],
        'val': $(this).is(':checked') ? true : false
      }
    });
  });

});

